import React, { useState, useEffect } from 'react';
import { TextField, Button, Snackbar, Alert, Container, AppBar, Toolbar, IconButton, Collapse, Typography, Grid, useTheme, LinearProgress, Paper, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import StarIcon from '@mui/icons-material/Star';
import translatedata from '../translate.json';
import Tutorial from '../component/Tutorial';
import Cookies from 'js-cookie';
import chestclose from '../media/chest/chestf.png';
import chestopen from '../media/chest/chestfopen.png';
import chestopening from '../media/chest/chest-unscreen.gif';
import CaseModal from '../component/casemodal';
import Qusetion from '../media/question.gif';
import Cark from '../media/cark.webp';
import Chat from '../media/chat.gif';
const Starrace = ({ isLoggedIn, selectedLanguage, setChatOpen, poseidonfee, zeusfee }) => {
  const [confirm, setConfirm] = useState();
  const [stardetail, setStarDetail] = useState(); // info, success, error
  const [stars, setStars] = useState();
  const [affstars, setAffStars] = useState();
  const [starsconf, setStarsConf] = useState([]);
  const [affiusers, setAffiUsers] = useState([]);
  const [promocode, setPromoCode] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [stat, setStat] = useState('error');
  const [affiusername, setAffiusername] = useState();
  const [promo_code, setPromo] = useState();
  const [cases, setCases] = useState([]);
  const query = new URLSearchParams(location.search);
  const hash = query.get('hash');
  const reqid = query.get('reqid');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [tutorialid, setTutorialid] = useState(4);
  const [tutorialopen, setTutorialOpen] = useState(false);
  const [cookies, setCookie] = useCookies(['serviceToken']);
  const [affiliatestars, setAffiliateStar] = useState();
  const [openmodal, setOpenModal] = useState(false);
  const [starcount, setStarCount] = useState(0);
  const [comp, setComp] = useState([]);
  const [coupon_total_bet, setCoupon_total_bet] = useState(0);
  const [progressPercentage, setPerc] = useState(0);
  const [star_history, setStar_history] = useState(0);
  const [openaff, setOpenaff] = useState([]);
  const [affiuserdetail, setAffiuserdetail] = useState([]);
  const [star_case_point_total, setStar_case_point_total] = useState(0);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const recheck = () => {
    const token = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios.post("https://api.youbetshop.com/api/user/mystarrace", "", config)
      .then(response => {
        const stardet = JSON.parse(response.data.data.star_detail)
        setStarDetail(stardet)
        setStarDetail(JSON.parse(response.data.data.star_detail))
        setStars(response.data.data.stars);
        setAffStars(Math.floor(Number((response.data.data.affi_users.reduce((total, user) => total + user.stars, 0)) / 2)));
        setStarsConf(response.data.data.star_conf);
        setAffiliateStar(response.data.data.affi_stars)
        setAffiUsers(response.data.data.affi_users)
        setPromoCode(response.data.data.promo_code)
        setStar_history(response.data.data.star_history)
        setCoupon_total_bet(response.data.data.coupon_total_bet)
        setAffiusername(response.data.data.affiusername)
        setStar_case_point_total(response.data.data.star_case_point_total)
        const progressPercentage = (response.data.data.coupon_total_bet / 60000) * 100;
        setPerc(progressPercentage)
        const task1 = stardet?.find((item) => item.id == 12)
        const task2 = stardet?.find((item) => item.id == 5)
        const task3 = stardet?.find((item) => item.id == 6)
        const task4 = stardet?.find((item) => item.id == 7)

        setComp([
          { id: '1', status: task1?.status, taskname: task1?.taskname, },
          { id: '2', status: task2?.status, taskname: task2?.taskname, },
          { id: '3', status: task3?.status, taskname: task3?.taskname, },
          { id: '4', status: task4?.status, taskname: task4?.taskname, },
        ])
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });
    axios.get("https://api.youbetshop.com/api/star-case/getstarcaseuser", config)
      .then(response => {
        setCases(response.data.data)
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });
  }
  useEffect(() => {
    const token = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios.post("https://api.youbetshop.com/api/user/mystarrace", "", config)
      .then(response => {
        const stardet = JSON.parse(response.data.data.star_detail)
        setStarDetail(stardet)
        setStars(response.data.data.stars);
        setAffStars(Math.floor(Number((response.data.data.affi_users.reduce((total, user) => total + user.stars, 0)) / 2)));
        setAffiliateStar(response.data.data.affi_stars)
        setStarsConf(response.data.data.star_conf);
        setAffiUsers(response.data.data.affi_users)
        setStar_history(response.data.data.star_history)
        setPromoCode(response.data.data.promo_code)
        setCoupon_total_bet(response.data.data.coupon_total_bet)
        setAffiusername(response.data.data.affiusername)
        setStar_case_point_total(response.data.data.star_case_point_total)
        const progressPercentage = (response.data.data.coupon_total_bet / 60000) * 100;
        setPerc(progressPercentage)
        const task1 = stardet?.find((item) => item.id == 12)
        const task2 = stardet?.find((item) => item.id == 5)
        const task3 = stardet?.find((item) => item.id == 6)
        const task4 = stardet?.find((item) => item.id == 7)

        setComp([
          { id: '1', status: task1?.status, taskname: task1?.taskname },
          { id: '2', status: task2?.status, taskname: task2?.taskname },
          { id: '3', status: task3?.status, taskname: task3?.taskname },
          { id: '4', status: task4?.status, taskname: task4?.taskname },
        ])
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });

    axios.get("https://api.youbetshop.com/api/star-case/getstarcaseuser", config)
      .then(response => {
        setCases(response.data.data)
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });
  }, [isLoggedIn])
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };
  const savePromocode = () => {
    const token = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let param = { promo_code: promo_code }
    axios.post("https://api.youbetshop.com/api/user/updatepromocode", param, config)
      .then(response => {
        if (response.data.success == true) {
          setMessage(response.data.message);
          setStat('success');
          setOpenSnack(true);
          recheck();
        } else {
          setMessage(response.data.message);
          setStat('error');
          setOpenSnack(true);
        }
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });
  }
  useEffect(() => {
    if (isLoggedIn) {
      console.log("User is logged in.");
      const tutorial = Cookies.get('tutorial');
      console.log("Tutorial cookie:", tutorial);
      if (tutorial !== "2") {
        setTutorialOpen(true);
        setTutorialid(6);
      }
    }
  }, [isLoggedIn]);
  const getAffiDet = (id) => {
    setOpenaff((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id); // ID varsa çıkart
      } else {
        return [...prev, id]; // ID yoksa ekle
      }
    });

    const token = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const affuser = affiuserdetail.find((item) => item.id == id)
    if (!affuser) {
      let param = { user_id: id }
      axios.post("https://api.youbetshop.com/api/user/starrace-network", param, config)
        .then(response => {
          if (response.data.success == true) {
            response.data.data.id = id
            setAffiuserdetail((prev) => {
              return [...prev, response.data.data];
            });
          }
        })
        .catch(error => {
          console.error('Hata', error);

        });
    }

  };

  const maskEmail = (email) => {
    const atIndex = email.indexOf('@');

    const localPart = email.slice(0, atIndex); // @ işaretinden önceki kısım
    const domainPart = email.slice(atIndex); // @ işaretinden sonraki kısım

    if (localPart.length < 4) return email; // Eğer yerel kısım 6 karakterden kısaysa, değiştirmeden döndür

    const firstThree = localPart.slice(0, 2);
    const lastThree = localPart.slice(-2);
    const masked = `${firstThree}***${lastThree}`;

    return masked + domainPart;
  };
  // Mevcut domaini kullanarak site URL'sini oluşturma
  const siteUrl = `${window.location.origin}?promocode=${promocode}`;

  const opentutorial = (id) => {
    setTutorialOpen(true);
    setTutorialid(id);
  }
  function CaseItem({ item }) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(item.opened_date));
    const [caseimg, setCaseimg] = useState(chestopen);
    const opencase = (status, id, count) => {
      if (status == 0) {
        setMessage(translatedata[selectedLanguage].starrace.plasewaitcase);
        setStat('error');
        setOpenSnack(true);
      } else {
        console.log(count)
        setCaseimg(chestopening)
        setTimeout(() => {
          setCaseimg(chestclose)
          const token = cookies.serviceToken;
          const config = {
            headers: { Authorization: `Bearer ${token}` },
          };
          let param = { case_id: id }
          axios.post("https://api.youbetshop.com/api/star-case/openstarcase", param, config)
            .then(response => {
              if (response.data.success == true) {
                setMessage(translatedata[selectedLanguage].starrace.casewin + count + translatedata[selectedLanguage].starrace.casewin2);
                setStat('success');
                setOpenSnack(true);
                setOpenModal(true)
                setStarCount(count)
                recheck();
              } else {
                setMessage(response.data.message);
                setStat('error');
                setOpenSnack(true);
              }
            })
            .catch(error => {
              console.error('Hata', error);
              setMessage(translatedata[selectedLanguage].starrace.pleaselogin);
              setStat('error');
              setOpenSnack(true);
            });
        }, 2000);
      }

    }
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(item.opened_date));
      }, 1000);

      return () => clearInterval(timer);
    }, [item.opened_date]);


    function calculateTimeLeft(targetDate) {
      // targetDate'i UTC olarak alıyoruz ve yerel zaman dilimine çeviriyoruz
      const targetUTC = new Date(targetDate);
      const targetLocal = new Date(targetUTC.getTime() + targetUTC.getTimezoneOffset() * 60000);

      const now = new Date(); // Şu anki zamanı alıyoruz

      const difference = targetLocal.getTime() - now.getTime(); // Zaman farkını milisaniye cinsinden alıyoruz
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }

      return timeLeft;
    }

    const formatTime = (value) => (value < 10 ? `0${value}` : value);

    // Kalan zamanı formatla
    const countdown = timeLeft.days !== undefined
      ? ` ${formatTime(timeLeft.hours)}:${formatTime(timeLeft.minutes)}:${formatTime(timeLeft.seconds)}`
      : "Kasa Açık";
    return (

      <div>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <img
              src={item.openedstatus === 1 ? caseimg : chestclose}
              alt={item.case_name}
              style={{ width: '100%', cursor: 'pointer' }}
              onClick={() => opencase(item.openedstatus, item.id, item.case_star_count)}
            /></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography sx={{ textAlign: "center" }}>{item.case_name}</Typography>
          </Grid>
          {item.openedstatus == 0 ?
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography sx={{ textAlign: "center", fontWeight: "600", color: "#c30000" }}>{countdown}</Typography>
            </Grid>
            :
            <Button variant="contained" color={"success"} sx={{ textAlign: "center" }} onClick={() => opencase(item.openedstatus, item.id, item.case_star_count)}>Kasayı Aç</Button>

          }
        </Grid>
      </div>

    );
  }




  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
            <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
              <ArrowBackIcon />
              <Typography variant="h6">
                {translatedata[selectedLanguage].starrace.home}
              </Typography>
            </IconButton>
            <IconButton edge="start" color="inherit" aria-label="back" onClick={() => setChatOpen(true)}>
              <img src={Chat} style={{ width: "25px" }} />
              <Typography variant="h6">
                {"Chat"}
              </Typography>
            </IconButton>
            <Typography variant="body1" style={{ marginRight: '10px' }}>
              {translatedata[selectedLanguage].starrace.starrace}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '40vh'
        }}
      >
        {isLoggedIn ?

          <>
            <Grid container spacing={2} sx={{ marginTop: "30px" }}>
              <Grid container spacing={2} >

                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginLeft: "15px" }}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box sx={{ justifyContent: "space-between", alignItems: "center", backgroundColor: "#2d2d2db8", marginBottom: "5px", display: "flex" }}>
                      <Typography variant="h4" sx={{ color: "#d79f09" }}>
                        {translatedata[selectedLanguage].header.starrace}
                      </Typography>
                      <img src={Qusetion} style={{
                        maxWidth: "50px",
                        display: "flex",
                        transition: "box-shadow 0.3s ease-in-out",
                        cursor: "pointer"
                      }}
                        onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                        onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                        onClick={() => opentutorial(6)} />
                    </Box>
                  </Grid>
                  <Paper elevation={3} sx={{ paddingLeft: 2, paddingBottom: 2 }}>
                    <Box
                      display="flex"
                      flexDirection={isMobile ? 'column' : 'row'}
                      justifyContent={isMobile ? 'flex-start' : 'space-between'}
                      alignItems={isMobile ? 'flex-start' : 'center'}
                    >      {isMobile &&
                      <Grid container justifyContent={"end"}>

                        <img src={Qusetion} style={{
                          maxWidth: "50px",
                          display: "flex",
                          transition: "box-shadow 0.3s ease-in-out",
                          cursor: "pointer"
                        }}
                          onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                          onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                          onClick={() => opentutorial(2)} />
                      </Grid>}
                      {isMobile &&
                        <Typography sx={{ fontWeight: "600", fontSize: "1.2rem" }}>{"Toplam Yıldız Sayısı : "}<span style={{ color: "#d79f09" }}>{stars}</span></Typography>
                      }
                      <Box display="flex" alignItems="center" sx={{ textAlign: "left", justifyContent: "space-between", width: isMobile ? "100%" : "20%", marginBottom: isMobile ? 2 : 0 }}>
                        <Typography>{translatedata[selectedLanguage].starrace.yourpromocode}<br /> {promocode}</Typography>
                        <IconButton onClick={() => handleCopy(promocode)} sx={{ marginLeft: 1 }}>
                          <FileCopyIcon />
                        </IconButton>
                      </Box>
                      <Box>
                        {!isMobile &&
                          <Typography sx={{ fontWeight: "600", fontSize: "1.2rem" }}>{"Toplam Yıldız Sayısı : "}<span style={{ color: "#d79f09" }}>{stars}</span></Typography>
                        }

                      </Box>
                      <Box display="flex" alignItems="center" sx={{ textAlign: isMobile ? 'left' : 'right', justifyContent: "space-between", width: isMobile ? "100%" : "40%" }}>
                        <Typography>
                          {translatedata[selectedLanguage].starrace.yourpromolink}<br />
                          <a href={siteUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#1976d2', marginLeft: 5 }}>
                            {siteUrl}
                          </a>
                        </Typography>
                        <IconButton onClick={() => handleCopy(siteUrl)} sx={{ marginLeft: 1 }}>
                          <FileCopyIcon />
                        </IconButton>
                        {!isMobile &&
                          <img src={Qusetion} style={{
                            maxWidth: "50px",
                            display: "flex",
                            transition: "box-shadow 0.3s ease-in-out",
                            cursor: "pointer"
                          }}
                            onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                            onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                            onClick={() => opentutorial(8)} />
                        }

                      </Box>

                    </Box>

                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>

                <Paper elevation={3} sx={{ padding: 2, width: '100%', marginBottom: '10px', position: 'relative' }}>
                  <Box textAlign="center" marginBottom={2}>
                    <Typography variant="h4" sx={{ color: "#333" }}>
                      {"Yıldız Görev Durumunuz"}
                    </Typography>
                    <Typography sx={{ color: "#333", fontSize: "0.9rem" }}>
                      {"Sizin ve affilate kullanıcılarınızın 4 görev tamamlaması durumunda kazançlarınız hesabınıza aktarılır.4 görev yapmayan affilate kullanıcıların yıldızları size her hangi bir kazanç sağlamaz.Lütfen kendinizin ve affilate kullanıcılarınızın 4 görev tamamladığından emin olun."}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100%', marginBottom: 2, position: 'relative' }}>
                    <Box sx={{ position: 'relative' }}>
                      <Box justifyContent={"space-between"} display={"flex"}>
                        <Typography variant="h6" sx={{ color: "#333", fontSize: "1rem", zIndex: "9" }}>
                          {"Yapılan Bahis:"}<span style={{ color: "green" }}>{coupon_total_bet}</span>
                        </Typography>
                        <Typography variant="h6" sx={{ color: "#333", fontSize: "1rem", zIndex: "9" }}>
                          {"Kalan Bahis:"}<span style={{ color: "red" }}>{60000 - coupon_total_bet}</span>
                        </Typography>
                      </Box>

                      <LinearProgress variant="determinate" value={progressPercentage} sx={{
                        height: '10px', zIndex: 1, borderRadius: "5px",
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: 'green'
                        }
                      }} />

                      {/* Dividing lines */}
                      <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2 }}>
                        <Box
                          sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '4px',
                            backgroundColor: 'white',
                            left: '25%',
                          }}
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '4px',
                            backgroundColor: 'white',
                            left: '50%',
                          }}
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '4px',
                            backgroundColor: 'white',
                            left: '75%',
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* Milestone labels */}
                  <Grid container justifyContent="space-between" sx={{ marginTop: 1 }}>
                    {comp.map((item, index) => (
                      <Grid item key={item.id}>
                        <Typography
                          variant="caption"
                          color={item.status === 1 ? '#078900' : 'text.secondary'}
                        >
                          {item.taskname} görevi {item.status === 1 ? 'tamamlandı' : 'beklemede'}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>

                <Paper elevation={3} sx={{ padding: 1, width: '100%', marginBottom: "10px" }}>
                  <Grid container justifyContent={"end"}>

                    <img
                      src={Qusetion}
                      alt="Question"
                      style={{
                        maxWidth: "50px",
                        display: "flex",
                        transition: "box-shadow 0.3s ease-in-out",
                        cursor: "pointer"
                      }}
                      onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                      onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                      onClick={() => opentutorial(5)}
                    />

                  </Grid>
                  <Box textAlign="center" marginBottom={2}>
                    <Typography variant="h4" sx={{ color: "#333" }}>
                      {translatedata[selectedLanguage].starrace.starcase}
                    </Typography>
                    <Typography sx={{ color: "#333" }}>
                      {translatedata[selectedLanguage].starrace.starcasetext}
                    </Typography>
                  </Box>
                  <Grid container>
                    {cases.map((item, index) => (
                      <Grid item key={index} xs={4} sm={4} md={4} lg={4}>
                        <CaseItem item={item} />
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
                <Paper elevation={3} sx={{ padding: isMobile ? 2 : 4, width: '100%' }}>
                  {isMobile &&
                    <Grid container justifyContent={"end"}>
                      <img src={Qusetion} style={{
                        maxWidth: "50px",
                        display: "flex",
                        transition: "box-shadow 0.3s ease-in-out",
                        cursor: "pointer"
                      }}
                        onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                        onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                        onClick={() => opentutorial(4)} />
                    </Grid>}

                  <Box textAlign="center" marginBottom={2} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                      <Typography variant="h4" sx={{ color: "#333" }}>
                        {translatedata[selectedLanguage].starrace.starpoint}
                      </Typography>
                      <Typography sx={{ color: "#333" }}>
                        {translatedata[selectedLanguage].starrace.starracetext2}
                      </Typography>
                    </Box>
                    {!isMobile &&
                      <img src={Qusetion} style={{
                        maxWidth: "50px",
                        display: "flex",
                        transition: "box-shadow 0.3s ease-in-out",
                        cursor: "pointer"
                      }}
                        onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                        onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                        onClick={() => opentutorial(4)} />
                    }
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        {/* <TableRow>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555' }}></TableCell>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.star}</TableCell>
                        </TableRow> */}
                      </TableHead>
                      <TableBody>
                        <TableRow  >
                          <TableCell>{translatedata[selectedLanguage].starrace.starpoint}</TableCell>
                          <TableCell>{stars - affiliatestars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} /></TableCell>
                        </TableRow>
                        <TableRow  >
                          <TableCell>{translatedata[selectedLanguage].starrace.affistar}</TableCell>
                          <TableCell>{affiliatestars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} /><br /><Typography sx={{ fontSize: "0.6rem" }}>{"Görevini Tamamlamayan Kullanıcıların Yıldızları Dahil Değildir"}</Typography></TableCell>
                        </TableRow>
                        <TableRow  >
                          <TableCell >

                            <Typography>{translatedata[selectedLanguage].starrace.totalstar}</Typography></TableCell>
                          <TableCell ><Typography>{stars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} /></Typography></TableCell>


                        </TableRow>
                        {/* 
                        <TableRow  >
                          <TableCell>{translatedata[selectedLanguage].starrace.poseidonstarcount}</TableCell>
                          <TableCell>{translatedata[selectedLanguage].starrace.totaldiscount} {starsconf.zeus_value*stars}%| {translatedata[selectedLanguage].starrace.nowposeidon} {poseidonfee}%</TableCell>
                        </TableRow>
                        <TableRow  >
                          <TableCell>{translatedata[selectedLanguage].starrace.zeusstarcount}</TableCell>
                          <TableCell>{translatedata[selectedLanguage].starrace.totaldiscount} {starsconf.zeus_value*stars}%| {translatedata[selectedLanguage].starrace.nowzeus} {zeusfee}%</TableCell>
                        </TableRow>
                         */}
                        <TableRow  >
                          <TableCell>{translatedata[selectedLanguage].starrace.starmoney}</TableCell>
                          <TableCell><Box sx={{ display: "flex", alignItems: "center" }}>{(starsconf.star_money_value * ((stars - affiliatestars) + affstars)).toFixed(2)} TL {"(" + starsconf.star_money_value + " TL)"}<img src={Qusetion} style={{
                            maxWidth: "50px",
                            display: "flex",
                            transition: "box-shadow 0.3s ease-in-out",
                            cursor: "pointer"
                          }}
                            onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                            onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                            onClick={() => opentutorial(12)} /></Box>  </TableCell>
                        </TableRow>
                        <TableRow  >
                          <TableCell>{"Geçen Hafta"}</TableCell>
                          <TableCell>{star_history} <StarIcon sx={{ color: 'grey', verticalAlign: 'middle', marginLeft: 1 }} /><br /><Typography sx={{ fontSize: "0.6rem" }}>{"Bilgi Amaçlıdır"}</Typography></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>

                <Paper elevation={3} sx={{ padding: 1, width: '100%', minHeight: !isMobile && "350px", marginBottom: "10px" }}>
                  <Grid container justifyContent={"end"}>
                    <Box textAlign="center" sx={{ width: "100%" }} marginBottom={2}>
                      <Typography variant="h4" sx={{ color: "#333" }}>
                        {"Yıldız Çarkı Çok Yakında"}
                      </Typography>
                      <img src={Cark} style={{ maxWidth: "250px", borderRadius: "10px" }} />
                    </Box>
                  </Grid>
                </Paper>
                <Paper elevation={3} sx={{ padding: isMobile ? 2 : 4, width: '100%', maxHeight: !isMobile ? "460px" : "100%" }}>

                  {isMobile &&
                    <Grid container justifyContent={"end"}>
                      <img src={Qusetion} style={{
                        maxWidth: "50px",
                        display: "flex",
                        transition: "box-shadow 0.3s ease-in-out",
                        cursor: "pointer"
                      }}
                        onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                        onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                        onClick={() => opentutorial(3)} />
                    </Grid>}
                  <Box textAlign="center" marginBottom={2} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                      <Typography variant="h4" sx={{ color: "#333" }}>
                        {translatedata[selectedLanguage].starrace.starracemission}
                      </Typography>
                      <Typography sx={{ color: "#333" }}>
                        {translatedata[selectedLanguage].starrace.starracetext}
                      </Typography>
                    </Box>
                    {!isMobile &&
                      <img src={Qusetion} style={{
                        maxWidth: "50px",
                        display: "flex",
                        transition: "box-shadow 0.3s ease-in-out",
                        cursor: "pointer"
                      }}
                        onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                        onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                        onClick={() => opentutorial(3)} />
                    }
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ alignItems: "center" }}>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555', padding: "5px" }}><Box sx={{ display: "flex", alignItems: "center" }}>{translatedata[selectedLanguage].starrace.missionname}  <img src={Qusetion} style={{
                            maxWidth: "50px",
                            display: "flex",
                            transition: "box-shadow 0.3s ease-in-out",
                            cursor: "pointer"
                          }}
                            onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                            onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                            onClick={() => opentutorial(0)} /></Box></TableCell>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555', padding: "5px" }}>{translatedata[selectedLanguage].starrace.missionstatus}</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555', display: "flex", alignItems: "center", padding: "5px" }}><Box sx={{ display: "flex", alignItems: "center" }}>{translatedata[selectedLanguage].starrace.starcount}<img src={Qusetion} style={{
                            maxWidth: "50px",
                            display: "flex",
                            transition: "box-shadow 0.3s ease-in-out",
                            cursor: "pointer"
                          }}
                            onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                            onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                            onClick={() => opentutorial(11)} /></Box></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stardetail?.map((item, index) => (
                          <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4' }}>
                            <TableCell sx={{ padding: "10px" }}>{item.taskname}</TableCell>
                            <TableCell sx={{ color: item.status == "1" ? 'green' : 'inherit', fontWeight: 'bold' }}>
                              {item.status == "1" ? '✓' : translatedata[selectedLanguage].starrace.waiting}
                            </TableCell>
                            <TableCell sx={{ padding: "10px" }}>
                              {item.value} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper elevation={3} sx={{ padding: isMobile ? 2 : 4, width: '100%' }}>
                  {isMobile &&
                    <Grid container justifyContent={"end"}>
                      <Box
                        component="img"
                        src={Qusetion}
                        sx={{
                          maxWidth: "50px",
                          
                        maxHeight:"50px",
                          display: "flex",
                          transition: "box-shadow 0.3s ease-in-out",
                          "&:hover": {
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
                          },
                        }}
                        onClick={() => opentutorial(5)}
                      />
                    </Grid>}
                  <Box textAlign="center" marginBottom={2} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                      {affiusername ?


                        <Typography sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].starrace.youraffi}<br /> {affiusername}</Typography>

                        :
                        <Box display="flex" alignItems="center" sx={{ textAlign: "center", marginBottom: isMobile ? 2 : 0 }}>
                          <TextField
                            margin="dense"
                            label={translatedata[selectedLanguage].starrace.promocodewrite}
                            type={'text'}
                            fullWidth
                            value={promo_code}
                            onChange={(e) => setPromo(e.target.value)}
                          />
                          <IconButton onClick={() => savePromocode()} sx={{ marginLeft: 1, backgroundColor: "#29963a" }}>
                            <CheckIcon />
                          </IconButton>
                        </Box>
                      }
                      <Typography variant="h4" sx={{ color: "#333" }}>
                        {translatedata[selectedLanguage].starrace.affiliatenet}
                      </Typography>
                      <Typography sx={{ color: "#333" }}>
                        {translatedata[selectedLanguage].starrace.starracetext3}
                      </Typography>
                    </Box>
                    {!isMobile &&
                      <img src={Qusetion} style={{
                        maxWidth: "50px",
                        maxHeight:"50px",
                        display: "flex",
                        transition: "box-shadow 0.3s ease-in-out",
                        cursor: "pointer"
                      }}
                        onMouseOver={(e) => (e.currentTarget.style.boxShadow = "0px 4px 15px rgba(0, 0, 0, 0.3)")}
                        onMouseOut={(e) => (e.currentTarget.style.boxShadow = "none")}
                        onClick={() => opentutorial(5)} />
                    }

                  </Box>
                  <TableContainer>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 'bold', color: '#555', maxWidth: "20px" }}></TableCell>
        <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.username}</TableCell>
        <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.starcountamo}</TableCell>
        <TableCell sx={{ fontWeight: 'bold', color: '#555' }}>{translatedata[selectedLanguage].starrace.missionstatus}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {affiusers?.map((user, index) => (
        <React.Fragment key={user.id}>
          <TableRow sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4' }}>
            <TableCell sx={{ fontWeight: 'bold', color: '#555', maxWidth: "20px" }}>
              {openaff.find((item) => item === user.id)?
                            <IconButton color="primary" onClick={() => getAffiDet(user.id)}>
                            <RemoveIcon />
                          </IconButton>
            :
            <IconButton color="primary" onClick={() => getAffiDet(user.id)}>
            <AddIcon />
          </IconButton>
            }

            </TableCell>
            <TableCell>{maskEmail(user.username)}</TableCell>
            <TableCell>
              {user.stars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} />
            </TableCell>
            <TableCell sx={{ color: user.job_count > 3 ? "#328500" : "#a90202" }}>
              {user.job_count > 3 ? "4 Görev Tamamlandı" : "4 Görev Tamamlanmadı"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={openaff.find((item) => item === user.id)} timeout="auto" unmountOnExit>
              <Typography variant='h6'>{maskEmail(user.username)} Kullanıcıları</Typography>
                <Table size="small" sx={{ width: "100%" }}>
                  <TableBody>
                    {affiuserdetail?.find((item) => item.id === user.id)?.affi_users.map((user1, index) => (
                      <React.Fragment key={user1.id}>
                        <TableRow sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4', width: "100%" }}>
                          <TableCell sx={{ fontWeight: 'bold', color: '#555', maxWidth: "20px" }}>
                          {openaff.find((item) => item === user1.id)?
                            <IconButton color="primary" onClick={() => getAffiDet(user1.id)}>
                            <RemoveIcon />
                          </IconButton>
                              :
                              <IconButton color="primary" onClick={() => getAffiDet(user1.id)}>
                              <AddIcon />
                            </IconButton>
                              }
                          </TableCell>
                          <TableCell>{maskEmail(user1.username)}</TableCell>
                          <TableCell>
                            {user1.stars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} />
                          </TableCell>
                          <TableCell sx={{ color: user1.job_count > 3 ? "#328500" : "#a90202" }}>
                            {user1.job_count > 3 ? "4 Görev Tamamlandı" : "4 Görev Tamamlanmadı"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openaff.find((item) => item === user1.id)} timeout="auto" unmountOnExit>
                              <Table size="small" sx={{ width: "100%" }}>
                                <TableBody>
                                  {affiuserdetail?.find((item) => item.id === user1.id)?.affi_users.map((user2, index) => (
                                    <TableRow key={user2.id} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4', width: "100%" }}>
                                      <TableCell>{maskEmail(user2.username)}</TableCell>
                                      <TableCell>
                                        {user2.stars} <StarIcon sx={{ color: 'gold', verticalAlign: 'middle', marginLeft: 1 }} />
                                      </TableCell>
                                      <TableCell sx={{ color: user2.job_count > 3 ? "#328500" : "#a90202" }}>
                                        {user2.job_count > 3 ? "4 Görev Tamamlandı" : "4 Görev Tamamlanmadı"}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </TableBody>
  </Table>
</TableContainer>

                </Paper>
              </Grid>
            </Grid>
          </>
          :
          <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
            <Typography sx={{ color: "#333" }}>
              {translatedata[selectedLanguage].starrace.pleaselogin}
            </Typography> </Paper>}

        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={stat}
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Container>
      <CaseModal openmodal={openmodal} setOpenModal={setOpenModal} starcount={starcount} selectedLanguage={selectedLanguage} />
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={stat}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Tutorial tutorialopen={tutorialopen} selectedLanguage={selectedLanguage} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid} />
    </>
  );
};

export default Starrace;
